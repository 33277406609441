<template>
  <span v-if="hasPerm('activities.add_coreactivityinscription')">
    <b-modal
      dialog-class="modal-xl"
      :id="modalId"
      ok-variant="primary"
      ok-only
      ok-title="Fermer"
      @shown="init()"
      @hidden="onClosed()"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-chess-knight"></i> Inscrire des membres à l'activité {{ activity.name }}
        </b>
      </template>
      <b-row>
        <b-col>
          <family-typeahead
            @change="onFamilyChanged($event.family)"
          >
          </family-typeahead>
        </b-col>
      </b-row>
      <b-row v-if="entity">
        <b-col>
          <entity-activities
            title="Inscriptions aux activités"
            :youth-home="activity.isYouthHome() ? 1 : 0"
            :entity="entity"
            :allow-edit="true"
            :show-all="true"
            :add-for-activity="activity"
            :adhesions="adhesions"
            :category="activity.category || 0"
          >
          </entity-activities>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import FamilyTypeahead from '@/components/Entity/FamilyTypeahead'
import EntityActivities from '@/components/Entity/EntityActivities'
import { BackendMixin } from '@/mixins/backend'
import { makeEntity } from '@/types/people'
import { makeAdhesion } from '@/types/adhesions'
import { BackendApi } from '@/utils/http'

export default {
  name: 'AddActivityInscriptionModal',
  components: { FamilyTypeahead, EntityActivities, },
  mixins: [BackendMixin],
  data() {
    return {
      family: null,
      entity: null,
      errorText: '',
      adhesions: [],
    }
  },
  props: {
    activity: Object,
    modalId: {
      type: String,
      default: 'add-activity-inscription-modal',
    },
  },
  watch: {
  },
  computed: {
  },
  methods: {
    ...mapActions(['addSuccess']),
    init() {
      this.entity = null
    },
    async onFamilyChanged(family) {
      this.family = family
      await this.loadEntity()
      await this.loadAdhesions()
    },
    onClosed() {
      this.family = null
      this.$emit('closed')
    },
    async loadEntity() {
      if (this.family) {
        try {
          const url = '/api/families/families/' + this.family.entity.id + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.entity = makeEntity(resp.data)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      } else {
        this.entity = null
      }
    },
    async loadAdhesions() {
      if (this.hasPerm('adhesions.view_adhesion')) {
        if (this.family) {
          let url = '/api/entity/' + this.family.entity.id + '/adhesions/'
          let backendApi = new BackendApi('get', url)
          try {
            let resp = await backendApi.callApi()
            this.adhesions = resp.data.map(makeAdhesion)
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
      }
    },
  },
}
</script>

<style scoped>
.error {
  background: #eee;
  color: #cc0000;
  padding: 4px 10px;
}
</style>
