<template>
  <span class="clone-activity-modal" v-if="activity && canAdd">
    <b-modal
      dialog-class="modal-md"
      :id="modalId"
      cancel-title="Annuler"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Ajouter"
      :ok-disabled="!isValid()"
    >
      <template v-slot:modal-title>
        <b>Dupliquer une activité</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div class="confirm-text">
        <b>Souhaitez-vous dupliquer l'activité {{ activity.name }}?</b><br />
        Une nouvelle activité avec les mêmes paramètres sera créée.
      </div>
      <b-row>
        <b-col>
          <b-form-group
            id="name-group"
            label="Nom"
            label-for="name"
            description="Nom de la nouvelle activité. Doit-être différent du nom actuel."
          >
            <b-form-input
              id="name"
              v-model="name"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label-for="schoolYear" label="Saison">
            <b-select v-model="selectedSchoolYear">
              <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                {{ schoolYear.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="L'activité apparaîtra dans la liste comme une sous-séance"
            v-if="activity.id"
          >
            <b-checkbox
              :disabled="noInheritance"
              v-model="inheritance"
            >
              Créer comme une séance d'activité
            </b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { makeActivity } from '@/types/activities'
import { BackendApi } from '@/utils/http'
import { makeSchoolYear } from '@/types/schools'

export default {
  name: 'clone-activity-button',
  mixins: [BackendMixin],
  props: {
    activity: Object,
    modalId: {
      type: String,
      default: 'clone-activity-modal',
    },
    forceInheritance: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
  data() {
    return {
      name: '',
      selectedSchoolYear: null,
      schoolYears: [],
      errorText: '',
      inheritance: false,
    }
  },
  computed: {
    canAdd() {
      return this.hasPerm('activities.add_coreactivity')
    },
    noInheritance() {
      return this.activity.inheritFrom !== null
    },
  },
  watch: {
    activity: function(newValue, oldValue) {
      this.init()
    },
    forceInheritance: function(newValue, oldValue) {
      this.inheritance = this.forceInheritance && !this.noInheritance
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    init() {
      if (this.activity) {
        this.name = this.activity.name
        this.inheritance = this.forceInheritance || (this.activity.inheritFrom !== null)
        this.loadSchoolYears()
      }
    },
    getInitialValue(array, expected) {
      const expectedId = (expected === null) ? 0 : expected.id
      for (let elt of array) {
        if (elt.id === expectedId) {
          return elt
        }
      }
      return null
    },
    async loadSchoolYears() {
      const backendApi = new BackendApi('get', '/api/school-years/')
      try {
        let resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt)).filter(elt => elt.isActive)
        this.selectedSchoolYear = this.getInitialValue(this.schoolYears, this.activity.schoolYear)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    isValid() {
      return (
        this.name &&
        this.selectedSchoolYear && this.selectedSchoolYear.id
      )
    },
    async onSave() {
      this.errorText = ''
      if (this.isValid()) {
        try {
          let url = '/api/activities/clone-activity/' + this.activity.id + '/'
          const backendApi = new BackendApi('post', url)
          let data = {
            name: this.name,
            school_year: this.selectedSchoolYear.id,
            inheritance: this.inheritance && !this.noInheritance,
          }
          const resp = await backendApi.callApi(data)
          const newActivity = makeActivity(resp.data)
          this.$bvModal.hide(this.modalId)
          await this.addSuccess('L\'activité "' + this.name + '" a été créé')
          this.$emit('create', newActivity)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .confirm-text {
    padding-bottom: 10px;
    border-bottom: solid 1px #ccc;
    margin-bottom: 10px;
  }
</style>
