<template>
  <span class="add-activity-manager" v-if="activity && activity.id && hasPerm('activities.add_activitymanager')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Ajouter"
      :ok-disabled="formInvalid"
    >
      <template v-slot:modal-title>
        <b><i class="fa fa-people-line"></i> Ajouter un animateur</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <b-row>
        <b-col>
          <b-form-group label="Recherche par nom de famille" label-for="individual-search">
            <individual-typeahead id="individual-search" @change="onIndividualChanged($event.individual)">
            </individual-typeahead>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="canAddMember || canAddContact">
        <b-col>
          <br />
          Vous ne trouvez pas la personne recherchée?<br />
          <a
            v-if="canAddMember"
            href @click.prevent="showAddMember()" class="btn btn-secondary btn-sm"
          >
            <i class="fa fa-people-roof"></i> Saisir un nouveau membre
          </a>
          &nbsp;
          <router-link
            v-if="canAddContact"
            :to="{ name: 'new-contact', }" class="btn btn-secondary btn-sm"
          >
            <i class="fa fa-contact-book"></i> Saisir un nouveau contact
          </router-link>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import IndividualTypeahead from '@/components/Individual/IndividualTypeahead'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeActivityManagedBy } from '@/types/activities'
import store from '@/store'

export default {
  name: 'AddActivityManagerModal',
  mixins: [BackendMixin],
  components: {
    IndividualTypeahead,
  },
  props: {
    activity: Object,
    modalId: {
      type: String,
      default: 'bv-modal-add-activity-manager',
    },
  },
  data() {
    return {
      errorText: '',
      individual: null,
    }
  },
  computed: {
    formInvalid() {
      return this.individual === null
    },
    canAddMember() {
      return this.hasPerm('families.add_family')
    },
    canAddContact() {
      return this.hasPerm('people.add_contact')
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onIndividualChanged(individual) {
      this.individual = individual
    },
    showAddMember() {
      this.$bvModal.hide(this.modalId)
      store.commit('showAddFamily', true)
    },
    async onSave() {
      if (this.individual) {
        this.errorText = ''
        let url = '/api/activity-managers/'
        const backendApi = new BackendApi('post', url)
        const data = {
          'activity': this.activity.id,
          'individual': this.individual.id,
        }
        try {
          const resp = await backendApi.callApi(data)
          const activityManager = makeActivityManagedBy(resp.data)
          const individual = activityManager.individual
          const gender = individual.isFemale() ? 'ajoutée comme animatrice' : 'ajouté comme animateur'
          await this.addSuccess('' + individual.fullName() + ' a été ' + gender)
          this.$bvModal.hide(this.modalId)
          this.$emit('done', activityManager)
        } catch (err) {
          this.errorText = this.getErrorText(err)
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
