<template>
  <div v-if="activity && activity.id">
    <div v-if="activity">
      <ul class="activity-detail">
        <li><i class="fa fa-calendar"></i> <b>Année:</b> {{ activity.schoolYear.name }}</li>
        <li v-if="activity.inheritFrom">
          <i class="fa fa-calendar-alt"></i>
          <router-link :to="getLinkToActivity(activity.inheritFrom)">
            Séance {{ activity.inheritFrom.name }}
          </router-link>
        </li>
        <li v-if="activity.heirs.length">
          <i class="fa fa-calendar-alt"></i>
          <counter-label :counter="activity.heirs.length" label="séance"></counter-label>
          <ul class="sub-ul">
            <li v-for="heir of activity.heirs" :key="heir.id">
              <router-link :to="getLinkToActivity(heir)">
                {{ heir.name }}
              </router-link>
            </li>
          </ul>
        </li>
        <li v-if="activity.getKeywords().length">
          <b><i class="fa fa-key"></i> Mots-clefs</b><br />
          <span v-for="keyword of activity.getKeywords()" :key="keyword">
            {{ keyword }}
          </span>
        </li>
        <li v-if="activity.startTime">
          <i class="fa fa-clock"></i> <b>Horaire:</b> {{ activity.startTime }}&nbsp;
          <span v-if="activity.endTime">- {{ activity.endTime }}</span>
        </li>
        <li v-if="!activity.startTime && activity.endTime">
          <i class="fa fa-clock"></i> <b>Horaire de fin:</b> {{ activity.endTime }}
        </li>
        <li v-if="activity.day">
          <i class="fa fa-calendar-times"></i> <b>Jour:</b> {{ activity.day }}
        </li>
        <li v-if="activity.startDate">
          <i class="fa fa-calendar-check"></i> <b>Début:</b>&nbsp;
          {{ activity.startDate | dateToString('DD/MM/YYYY') }}
        </li>
        <li v-if="activity.duration > 0">
          <i class="fa fa-clock"></i> <b>Durée:</b> {{ duration(activity.duration) }}
        </li>
        <li v-if="activity.place.id">
          <i class="fa fa-location-dot"></i> <b>Lieu:</b> {{ activity.place.name }}
        </li>
        <li v-if="activity.seancesCount">
          <i class="fa fa-chevron-circle-right"></i> <b>Nb de séances:</b> {{ activity.seancesCount }}
        </li>
        <li v-if="activityDatesCount !== null">
          <b-row>
            <b-col><i class="fa fa-calendar-times"></i> <b>Dates</b></b-col>
            <b-col class="text-right" v-if="viewActivityDatesButton">
              <a href @click.prevent="viewActivityDates" class="btn btn-xs btn-secondary">
                Voir les dates
              </a>
            </b-col>
          </b-row>
          <div>
            <b>Nb de dates:</b> {{ activityDatesCount }}
          </div>
        </li>
        <li v-if="!isYouthHome && activity.allowPresence">
          <b-row>
            <b-col>
              <span>
                <i class="fa fa-calendar-xmark"></i> Pointage des présences
              </span>
            </b-col>
          </b-row>
        </li>
        <li v-if="!isYouthHome && activity.weeklyPresence">
          <b-row>
            <b-col>
              <span>
                <i class="fa fa-calendar-week"></i> Listing Presence Hebdomadaire
              </span>
            </b-col>
          </b-row>
        </li>
        <li v-if="canViewFlyer">
          <b-row>
            <b-col><i class="fas fa-newspaper"></i> Flyer</b-col>
            <b-col class="text-right">
              <a href @click.prevent="viewActivityFlyer" class="btn btn-xs btn-secondary">
                Voir le flyer
              </a>
            </b-col>
          </b-row>
        </li>
        <li v-if="!isYouthHome && activityDatesCount">
          <b-row>
            <b-col>
              <span title="fichier à partager pouvant être importé dans vos logiciels d'agenda" v-b-tooltip>
                <i class="fa fa-square-rss"></i> iCAL
              </span>
            </b-col>
            <b-col class="text-right">
              <a href @click.prevent="toggleIcal" class="btn btn-xs btn-secondary" v-if="canChange">
                <span v-if="icalUrl">Désactiver</span>
                <span v-else>Activer</span>
              </a>
            </b-col>
          </b-row>
          <div v-if="icalUrl">
            <a :href="icalUrl" target="_blank">
              Télécharger le fichier
            </a>
          </div>
          <div v-else>Non</div>
        </li>
        <li v-if="activity.minimumParticipants || activity.maximumParticipants">
          <i class="fa fa-users"></i> <b>Nb de Participants</b>
          <div v-if="activity.minimumParticipants"><b>Min:</b> {{ activity.minimumParticipants }}</div>
          <div v-if="activity.maximumParticipants"><b>Max:</b> {{ activity.maximumParticipants }}</div>
        </li>
        <li v-if="activity.about">
          <i class="fa fa-list"></i> <b>A propos</b>
          <div>{{ activity.about }}</div>
        </li>
        <li v-if="activity.conditions">
          <i class="fa fa-check-double"></i> <b>Conditions</b>
          <div>{{ activity.conditions }}</div>
        </li>
        <li>
          <i class="fa fa-euro"></i> <b>{{ activityPricingRuleName }}</b>
          <div>{{ activityPrices }}</div>
        </li>
        <li v-if="hasPerm('payments.view_analyticaccount')">
          <i class="fa fa-pie-chart"></i> <b>Compte Analytique</b>
          <div>{{ activity.analyticAccount.getLabel() }}</div>
        </li>
        <li v-if="hasPerm('payments.view_generalaccount')">
          <i class="fa fa-table"></i> <b>Compte général</b>
          <div>{{ activity.generalAccount.getFullLabel() }}</div>
        </li>
        <li v-if="hasPerm('activities.view_activitytype') && activity.activityType.id">
          <i class="fa fa-chess"></i> <b>Type d'activité</b>
          <div>{{ activity.activityType.name }}</div>
        </li>
        <li v-if="activity.allowAllMembers">
          <b><i class="fa fa-person-burst"></i> Inscriptions</b><br />
          Autorise les intervenants
        </li>
        <li v-if="hasPerm('activities.view_activitymanager') && activity.id">
          <i class="fa fa-people-line"></i> <b>Animateurs</b>&nbsp;
          <a
            v-if="canChangeManagers && hasPerm('activities.add_activitymanager')"
            href
            @click.prevent="showAddActivityManager"
          >
            <i class="fa fa-plus"></i>
          </a>
          <div v-if="activity.managedBy.length === 0" class="empty-text">
            Aucun animateur
          </div>
          <div v-for="manager of activity.managedBy" :key="manager.id" class="activity-manager">
            <b-row>
              <b-col>
                <a href @click.prevent="showIndividualSidebar(manager.individual)">
                  {{ manager.individual.fullName() }}
                </a>
              </b-col>
              <b-col cols="2" v-if="canChangeManagers">
                <a
                  v-if="hasPerm('activities.delete_activitymanager')"
                  href
                  @click.prevent="showDeleteActivityManager(manager.individual)"
                >
                  <i class="fa fa-close"></i>
                </a>
              </b-col>
            </b-row>
            <div v-if="manager.individual.cellPhone">
              <phone-link :phone="manager.individual.cellPhone"></phone-link>
            </div>
            <div v-if="manager.individual.proPhone">
              <phone-link :phone="manager.individual.proPhone"></phone-link>
            </div>
            <div v-if="manager.individual.email">
              <email-link :email="manager.individual.email"></email-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <add-activity-manager-modal
      :activity="activity"
      :modal-id="addActivityManagerModal"
      @done="onActivityManagerAdded($event)"
    >
    </add-activity-manager-modal>
    <confirm-modal
      name="delete-activity-manager"
      title="Suppression d'un animateur"
      :text="confirmDeleteManagerText"
      :object="selectedManager"
      @confirmed="onDeleteManager"
    >
    </confirm-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import router from '@/router'
import { BackendMixin } from '@/mixins/backend'
import AddActivityManagerModal from '@/components/Activities/AddActivityManager'
import ConfirmModal from '@/components/Modals/ConfirmModal'
import CounterLabel from '@/components/Controls/CounterLabel'
import EmailLink from '@/components/Controls/Links/EmailLink'
import PhoneLink from '@/components/Controls/Links/PhoneLink'
import { currency, duration } from '@/filters/texts'
import { ActivityPricingRule, getActivityPricingRuleName, makeActivity } from '@/types/activities'
import { BackendApi } from '@/utils/http'

export default {
  name: 'ActivityInfo',
  mixins: [BackendMixin],
  components: {
    ConfirmModal,
    AddActivityManagerModal,
    CounterLabel,
    EmailLink,
    PhoneLink,
  },
  props: {
    isYouthHome: Boolean,
    activity: Object,
    canChangeManagers: Boolean,
    forceDatesCount: {
      type: Number,
      defaultValue: -1,
    },
  },
  data() {
    return {
      addActivityManagerModal: 'bv-add-activity-manager-modal',
      selectedManager: null,
      activityDatesCount: null,
      icalUrl: '',
    }
  },
  computed: {
    activityPricingRuleName() {
      if (this.activity) {
        return getActivityPricingRuleName(this.activity.pricingRule, this.activity.flatPrices.length > 0)
      }
      return ''
    },
    activityPrices() {
      if (this.activity) {
        return this.activity.getPriceName()
      }
      return ''
    },
    confirmDeleteManagerText() {
      if (this.selectedManager && this.activity) {
        return 'Souhaitez-vous supprimer ' + this.selectedManager.fullName() + ' de la liste des animateurs' +
          ' de l\'activité ' + this.activity.name
      }
      return '?'
    },
    viewActivityDatesButton() {
      return router.resolve(this.getLinkToActivityDates()).href !== window.location.pathname
    },
    canViewFlyer() {
      return this.hasPerm('activities.view_coreactivity')
    },
    canChange() {
      return this.hasPerm('activities.change_coreactivity')
    },
  },
  watch: {
    activity() {
      this.activityDatesCount = null
      if (this.activity) {
        this.icalUrl = this.activity.icalUrl
      }
      this.loadDatesCount()
    },
    forceDatesCount() {
      this.loadDatesCount()
    },
  },
  mounted() {
    if (this.activity) {
      this.icalUrl = this.activity.icalUrl
    }
    this.loadDatesCount()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    duration(value) {
      return duration(value)
    },
    showAddActivityManager() {
      this.$bvModal.show(this.addActivityManagerModal)
    },
    showDeleteActivityManager(individual) {
      this.selectedManager = individual
      this.$bvModal.show('bv-confirm-modal:delete-activity-manager')
    },
    onActivityManagerAdded(event) {
      this.activity.managedBy.push(event)
    },
    async loadDatesCount() {
      if (this.forceDatesCount >= 0) {
        this.activityDatesCount = this.forceDatesCount
      } else {
        if (this.hasPerm('agenda.view_activitydate')) {
          try {
            let url = '/agenda/api/activity-dates/count/?activity=' + this.activity.id
            let backendApi = new BackendApi('get', url)
            let resp = await backendApi.callApi()
            this.activityDatesCount = resp.data.count
          } catch (err) {
            await this.addError(this.getErrorText(err))
          }
        }
      }
    },
    async toggleIcal() {
      let url = '/api/activities/set-activity-ical/' + this.activity.id + '/'
      const backendApi = new BackendApi('post', url)
      try {
        const resp = await backendApi.callApi({})
        const activity = makeActivity(resp.data)
        this.icalUrl = activity.icalUrl
        let msg = (this.icalUrl) ? 'ical a été activé' : 'ical a été désactivé'
        await this.addSuccess(msg)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async onDeleteManager(event) {
      let url = '/api/activity-managers/'
      const individual = event.object
      const backendApi = new BackendApi('delete', url)
      const data = {
        'activity': this.activity.id,
        'individual': individual.id,
      }
      try {
        const resp = await backendApi.callApi(data)
        const gender = individual.isFemale() ? 'supprimée comme animatrice' : 'supprimé comme animateur'
        await this.addSuccess('' + individual.fullName() + ' a été ' + gender)
        const index = this.activity.managedBy.map(elt => elt.individual.id).indexOf(individual.id)
        if (index >= 0) {
          this.activity.managedBy.splice(index, 1)
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    getLinkToActivity(activity) {
      if (this.isYouthHome) {
        return { name: 'youth-homes-detail', params: { activityId: '' + activity.id, }, }
      } else {
        return { name: 'activities-detail', params: { activityId: '' + activity.id, }, }
      }
    },
    getLinkToActivityDates() {
      return {
        name: 'activity-dates',
        params: { activityId: '' + this.activity.id, youthHome: this.isYouthHome ? '1' : '0', },
      }
    },
    getLinkToActivityFlyer() {
      return {
        name: 'activity-flyer',
        params: { activityId: '' + this.activity.id, youthHome: this.isYouthHome ? '1' : '0', },
      }
    },
    viewActivityDates() {
      router.push(this.getLinkToActivityDates())
    },
    viewActivityFlyer() {
      return router.push(this.getLinkToActivityFlyer())
    },
  },
}
</script>

<style scoped>
  ul.activity-detail {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.activity-detail li {
    padding: 4px;
    margin-bottom: 4px;
    border-bottom: solid 1px #ccc;
  }

  ul.activity-detail li:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }
  .activity-title {
    padding: 5px;
    background: #e0e0e0;
  }
  .disabled {
    background: #ffc0c0;
  }
  ul.sub-ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul.sub-ul li {
    margin-left: 20px;
    padding: 2px;
  }
  .activity-manager {
    padding: 3px;
    background: #eee;
    margin-bottom: 3px;
  }
</style>
